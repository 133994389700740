import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import uploadIcon from "../IMg/uploadIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import { IoIosCloseCircle } from "react-icons/io";
import Loader from "../../CompanyFolder/CompanyList/Loader";
import { Input } from "reactstrap";
import heic2any from 'heic2any';

const PhotoUploadSection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pictureData, setPictureData] = useState([]);
  const [uploadedImages, setUploadedImages] = useState({});
  const [loading, setLoading] = useState(false);
  const [explanations, setExplanations] = useState({});
  const fileInputRefs = useRef([]);
  const [queryParams, setQueryParams] = useState({});
  const [hashParams, setHashParams] = useState('');
  const [requestData, setRequestData] = useState();
  const currentUser = (JSON.parse(localStorage.getItem("userData"))).designation;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = {};
    for (let [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }
    setQueryParams(paramsObject);
    const hash = location.hash;
    if (hash) {
      setHashParams(hash.substring(1));

    }
  }, [location]);

  const getRequestsList = async () => {
    const msiId = queryParams.requestId;

    if (Number(location.state?.requestId) || Number(msiId)) {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestsList`,
        {},
        { requestId: Number(location.state?.requestId) || Number(msiId) },
        "GET"
      );

      if (isSuccess) {
        setRequestData(data?.data[0])
      }
    }
  };

  const getPictureFormQuestionList = async () => {
    setLoading(true);

    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getPictureFormQuestionList`,
        {},
        { type: "ALL" },
        "GET"
      );
      setLoading(false);

      if (isSuccess && Array.isArray(data.data)) {
        setPictureData(data.data);
      } else {
        console.error("API response is not an array:", data);
        setPictureData([]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setPictureData([]);
    }
  };
  const convertHeicToImage = async (url) => {
    try {
      const response = await fetch(url); 
      const blob = await response.blob();
      const convertedBlob = await heic2any({ blob, toType: 'image/jpeg' }); 
      const objectURL = URL.createObjectURL(convertedBlob); 
      return objectURL;
    } catch (error) {
      console.error('Error converting HEIC file:', error);
      return null; 
    }
  };
  
  const convertHeicFields = async (data) => {
    const convertedData = { ...data };
  
    for (let i = 1; i <= 19; i++) {
      const pField = `p${i}`;
      if (convertedData[pField] && convertedData[pField].endsWith('.heic')) {
        const convertedImageUrl = await convertHeicToImage(convertedData[pField]);
        if (convertedImageUrl) {
          convertedData[pField] = convertedImageUrl;
        }
      }
    }
  
    return convertedData; 
  };
  const getRequestPictures = async () => {
    const msiId = queryParams.requestId;
    if(location.state?.requestId || msiId){
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestPictures`,
        {},
        { requestId: location.state?.requestId || msiId },
        "GET"
      );
      if (isSuccess && data.data.length) {
        const tmpData = data.data[0]
        const convertedData = await convertHeicFields(tmpData);
        setUploadedImages(convertedData);
        for (let i = 1; i <= 19; i++) {
          const key = `p${i}a`;
          if (tmpData[key] !== null) {
            explanations[key] = true;
            explanations[`${key}value`] = tmpData[key];
          }
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }}
  };

  const uploadPicture = async (pictureUrl, pictureId) => {
    const msiId = queryParams.requestId;
  
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude, accuracy } = position.coords;
  
          console.log(`Latitude: ${latitude}`);
          console.log(`Longitude: ${longitude}`);
          console.log(`Location accuracy: ${accuracy} meters`);
  
          try {
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyB0wPOU6UmnsaXpt7lJuCG8SjEEYBrEttI`
            );
            const geocodeData = await response.json();
  
            let jioAddress = "Address not found";
            if (geocodeData.results && geocodeData.results[0]) {
              jioAddress = geocodeData.results[0].formatted_address;
            }
  
            const { isSuccess, data } = await apiCall(
              `${config.POST_LOGIN_API_URL}uploadPicture`,
              {},
              {
                requestId: Number(location.state?.requestId) || Number(msiId),
                pictureId,
                pictureUrl,
                latitude,
                longitude,
                jioAddress, // Include the full address in the payload
              },
              "POST"
            );
  
            if (isSuccess) {
              const tmpData = data.data[0];
              const convertedData = await convertHeicFields(tmpData);
              setUploadedImages(convertedData);
            }
          } catch (error) {
            console.error("Failed to fetch data or geolocation error:", error);
          }
        },
        (error) => {
          console.error("Geolocation error:", error.message);
        },
        {
          enableHighAccuracy: true, // Request high-accuracy mode
          timeout: 10000,           // Wait up to 10 seconds for a precise location
          maximumAge: 0             // Prevent caching and request a fresh location
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  
  

  const completePictureform = async () => {
    const msiId = queryParams.requestId;
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}completePictureform`,
        {},
        { requestId: Number(location.state?.requestId) || Number(msiId) },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const deletedPicture = async (pictureUrl, pictureId) => {
    const msiId = queryParams.requestId;
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}deletedPicture`,
        {},
        { requestId: Number(location.state?.requestId) || Number(msiId), pictureId, pictureUrl },
        "POST"
      );
      if (isSuccess) {
        const tmpData = data.data[0]
        const convertedData = await convertHeicFields(tmpData);
        setUploadedImages(convertedData);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleFileChange = async (event, pictureId) => {
    try {
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}_${event.target.files[0]?.name}`;
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("fileName", fileName);
      formData.append("filePath", "msi/");

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      const response = await fetch(
        `${config.AUTH_API_URL}uploadFile`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const result = await response.json();
      const url = result.url;
      uploadPicture(url, pictureId);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getPictureFormQuestionList();
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      if (location.state?.requestId || queryParams.requestId)
        getRequestPictures();
      getRequestsList();
    }
  }, [location.state?.requestId, queryParams]);

  const textStyle = {
    fontSize: "16px",
    color: "rgba(0, 80, 164, 1)",
  };

  const handleIconClick = (index) => {
    fileInputRefs.current[index].click();
  };

  const handleCheckboxChange = (index) => {
    if (explanations && explanations[index])
      deletedPicture(String(null), index + 'value');

    const parts = index.split('a');
    if (uploadedImages[parts[0]]) {
      deletedPicture(String(null), parts[0]);
    }
    setExplanations((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const uploadPictureData = async (pictureUrl, pictureId) => {
    const msiId = queryParams.requestId;
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}uploadPicture`,
        {},
        { requestId: Number(location.state?.requestId) || Number(msiId), pictureId, pictureUrl },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleExplanationChange = (index, value) => {
    setExplanations((prev) => ({
      ...prev,
      [index]: value,
    }));
    uploadPictureData(value, index)
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container mt-4" style={textStyle}>
   
      {requestData?.status != '4' && <>
        <span className="d-flex justify-content-end">
        <button onClick={completePictureform} className="btn btn-success next-btn ps-4 pe-4 mb-3">
          Complete
        </button>
      </span>
      </>}

      {pictureData.map((picture, index) => (
        picture.reportId == 'P19' ? (
          currentUser == 'admin' ?
            <div className="mb-4" key={picture.id}>
              <h5 style={{ fontSize: "14px" }}>
                {picture.reportId}: {picture.title}
              </h5>
              <div className="p-2" style={{ border: "1px solid #0050A4" }}>
                <div
                  style={{ borderBottom: "1px solid rgba(0, 80, 164, 1)" }}
                  onClick={() => handleIconClick(index)}
                >
                  <i
                    style={{
                      backgroundColor: "#0050A4",
                      color: "white",
                      padding: "5px",
                      cursor: "pointer",
                    }}
                    className="bi bi-cloud-upload"
                  ></i>
                  <span style={{ marginLeft: "20em", cursor: "pointer" }}>
                    Click here to upload the image
                  </span>
                </div>
                <div className="d-flex">
                  <div className="position-relative m-2 mt-4">
                    <div style={{ position: "relative", display: "inline-block" }}>
                      {uploadedImages[picture.reportId.split(".")[0].toLowerCase()] ? (
                        <>
                          <img
                            src={uploadedImages[picture.reportId.split(".")[0].toLowerCase()]}
                            alt="Uploaded"
                            className="img-fluid"
                            style={{
                              background: "#EDF4FC",
                              padding: "20px",
                              height: "30vh",
                              width: "20vw",
                            }}
                          />
                          <IoIosCloseCircle
                            onClick={() =>
                              deletedPicture(
                                picture.reportId.split(".")[0].toLowerCase(),
                                picture.reportId.split(".")[0].toLowerCase()
                              )
                            }
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                              fontSize: "24px",
                              color: "black",
                              cursor: "pointer",
                            }}
                          />
                          <div>
                            Uploaded Time : {(() => {
                              try {
                                // First attempt: Try to parse without reformatting
                                const date = new Date(uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']);
                                if (isNaN(date.getTime())) throw new Error("Invalid Date"); // Check if the date is invalid

                                return date.toLocaleString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                            
                                });
                              } catch {
                                // Fallback: Reformat date to MM-DD-YYYY format and parse again
                                const reformattedDate = new Date(
                                  uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']
                                    .replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")
                                );
                                return reformattedDate.toLocaleString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                               
                                });
                              }
                            })()}
                          </div>




                        </>
                      ) : (
                        <img
                          src={uploadIcon}
                          alt="placeholder"
                          className="img-fluid"
                          style={{
                            background: "#EDF4FC",
                            padding: "20px",
                            height: "30vh",
                            width: "20vw",
                          }}
                        />
                      )}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      ref={(ref) => (fileInputRefs.current[index] = ref)}
                      onChange={(e) =>
                        handleFileChange(e, picture.reportId.split(".")[0].toLowerCase())
                      }
                      style={{ display: "none" }}
                    />
                    {picture.reportId === 'P19' ? (
                      <div className="mt-3">
                        <div className="input-group" id="inputGroup_P19">
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${requestData?.siteAddress},+${requestData?.siteCity},+${requestData?.siteState}+${requestData?.siteZipcode}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Location on Google Maps
                          </a>
                        </div>
                        <label
                          htmlFor={`unable-${index}`}
                          className="ms-2"
                          style={{ fontSize: "14px" }}
                        >
                          Remarks:
                        </label>
                        <Input
                          className="mt-2"
                          placeholder="Please explain location..."
                          value={explanations['p19avalue'] || ""}
                          onChange={(e) => {
                            uploadPictureData(e.target.value, 'p19avalue');
                            setExplanations((prev) => ({
                              ...prev,
                              p19avalue: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    ) : (
                      <div className="mt-3">
                        <input
                          type="checkbox"
                          id={`unable-${index}`}
                          style={{ width: "20px" }}
                          checked={!!explanations[picture.reportId.split(".")[0].toLowerCase() + "a"]}
                          onChange={() => handleCheckboxChange(picture.reportId.split(".")[0].toLowerCase() + "a")}
                        />
                        <label
                          htmlFor={`unable-${index}`}
                          className="ms-2"
                          style={{ fontSize: "14px" }}
                        >
                          Unable to take photo, please explain
                        </label>
                        {explanations[picture.reportId.split(".")[0].toLowerCase() + "a"] && (
                          <>
                            <Input
                              className="mt-2"
                              placeholder="Please explain why you were unable to take a photo..."
                              value={explanations[picture.reportId.split(".")[0].toLowerCase() + "a" + "value"] || ""}
                              onChange={(e) => handleExplanationChange(picture.reportId.split(".")[0].toLowerCase() + "a" + "value", e.target.value)}
                            />
                            <div>
                              Uploaded Time : {(() => {
                                try {
                                  // First attempt: Try to parse without reformatting
                                  const date = new Date(uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']);
                                  if (isNaN(date.getTime())) throw new Error("Invalid Date"); // Check if the date is invalid

                                  return date.toLocaleString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                                 
                                  });
                                } catch {
                                  // Fallback: Reformat date to MM-DD-YYYY format and parse again
                                  const reformattedDate = new Date(
                                    uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']
                                      .replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")
                                  );
                                  return reformattedDate.toLocaleString("en-US", {
                                    month: "2-digit",
                                    day: "2-digit",
                                    year: "numeric",
                              
                                  });
                                }
                              })()}
                            </div>


                          </>

                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div> : <></>
        ) : (
          <div className="mb-4" key={picture.id}>
            <h5 style={{ fontSize: "14px" }}>
              {picture.reportId}: {picture.title}
            </h5>
            <div className="p-2" style={{ border: "1px solid #0050A4" }}>
              <div
                style={{ borderBottom: "1px solid rgba(0, 80, 164, 1)" }}
                onClick={() => handleIconClick(index)}
              >
                <i
                  style={{
                    backgroundColor: "#0050A4",
                    color: "white",
                    padding: "5px",
                    cursor: "pointer",
                  }}
                  className="bi bi-cloud-upload"
                ></i>
                <span style={{ marginLeft: "20em", cursor: "pointer" }}>
                  Click here to upload the image
                </span>
              </div>
              <div className="d-flex">
                <div className="position-relative m-2 mt-4">
                  <div style={{ position: "relative", display: "inline-block" }}>
                    {uploadedImages[picture.reportId.split(".")[0].toLowerCase()] ? (
                      <>
                        <img
                          src={uploadedImages[picture.reportId.split(".")[0].toLowerCase()]}
                          alt="Uploaded"
                          className="img-fluid"
                          style={{
                            background: "#EDF4FC",
                            padding: "20px",
                            height: "30vh",
                            width: "20vw",
                          }}
                        />
                        <IoIosCloseCircle
                          onClick={() =>
                            deletedPicture(
                              picture.reportId.split(".")[0].toLowerCase(),
                              picture.reportId.split(".")[0].toLowerCase()
                            )
                          }
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            fontSize: "24px",
                            color: "black",
                            cursor: "pointer",
                          }}
                        />
                        <div>
                          Uploaded Location : {uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'Location']}
                        </div>
                        <div>
                          Uploaded Time : {(() => {
                            try {
                              // First attempt: Try to parse without reformatting
                              const date = new Date(uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']);
                              if (isNaN(date.getTime())) throw new Error("Invalid Date"); // Check if the date is invalid

                              return date.toLocaleString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                              
                              });
                            } catch {
                              // Fallback: Reformat date to MM-DD-YYYY format and parse again
                              const reformattedDate = new Date(
                                uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']
                                  .replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")
                              );
                              return reformattedDate.toLocaleString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                             
                              });
                            }
                          })()}
                        </div>



                      </>
                    ) : (
                      <img
                        src={uploadIcon}
                        alt="placeholder"
                        className="img-fluid"
                        style={{
                          background: "#EDF4FC",
                          padding: "20px",
                          height: "30vh",
                          width: "20vw",
                        }}
                      />
                    )}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    ref={(ref) => (fileInputRefs.current[index] = ref)}
                    onChange={(e) =>
                      handleFileChange(e, picture.reportId.split(".")[0].toLowerCase())
                    }
                    style={{ display: "none" }}
                  />
                  {picture.reportId === 'P19' ? (
                    <div className="mt-3">
                      <div className="input-group" id="inputGroup_P19">
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${location.state?.requestData?.siteAddress},+${location.state?.requestData?.siteCity},+${location.state?.requestData?.siteState}+${location.state?.requestData?.siteZipcode}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Location on Google Maps
                        </a>
                      </div>
                      <label
                        htmlFor={`unable-${index}`}
                        className="ms-2"
                        style={{ fontSize: "14px" }}
                      >
                        Remarks:
                      </label>
                      <Input
                        className="mt-2"
                        placeholder="Please explain location..."
                        value={explanations['p19avalue'] || ""}
                        onChange={(e) => {
                          uploadPictureData(e.target.value, 'p19avalue');
                          setExplanations((prev) => ({
                            ...prev,
                            p19avalue: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  ) : (
                    <div className="mt-3">
                      <input
                        type="checkbox"
                        id={`unable-${index}`}
                        style={{ width: "20px" }}
                        checked={!!explanations[picture.reportId.split(".")[0].toLowerCase() + "a"]}
                        onChange={() => handleCheckboxChange(picture.reportId.split(".")[0].toLowerCase() + "a")}
                      />
                      <label
                        htmlFor={`unable-${index}`}
                        className="ms-2"
                        style={{ fontSize: "14px" }}
                      >
                        Unable to take photo, please explain
                      </label>
                      {explanations[picture.reportId.split(".")[0].toLowerCase() + "a"] && (
                        <>
                          <Input
                            className="mt-2"
                            placeholder="Please explain why you were unable to take a photo..."
                            value={explanations[picture.reportId.split(".")[0].toLowerCase() + "a" + "value"] || ""}
                            onChange={(e) => handleExplanationChange(picture.reportId.split(".")[0].toLowerCase() + "a" + "value", e.target.value)}
                          />
                          <div>
                            Uploaded Time : {(() => {
                              try {
                                // First attempt: Try to parse without reformatting
                                const date = new Date(uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']);
                                if (isNaN(date.getTime())) throw new Error("Invalid Date"); // Check if the date is invalid

                                return date.toLocaleString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                
                                });
                              } catch {
                                // Fallback: Reformat date to MM-DD-YYYY format and parse again
                                const reformattedDate = new Date(
                                  uploadedImages[picture.reportId.split(".")[0].toLowerCase() + 'time']
                                    .replace(/(\d{2})-(\d{2})-(\d{4})/, "$2-$1-$3")
                                );
                                return reformattedDate.toLocaleString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                             
                                });
                              }
                            })()}
                          </div>

                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      ))}
    </div>

  );
};

export default PhotoUploadSection;
